import React from "react";

import img_sokoban_pc8801_popcom_1984_issue_august_title
    from "../../../assets/screenshots/sokoban_pc8801_popcom_1984_issue_august_title.png";
import img_sokoban_pc8801_popcom_1984_issue_august_001
    from "../../../assets/screenshots/sokoban_pc8801_popcom_1984_issue_august_001.png";

export default function ArticleSokobanPc8801PortFromMulti8() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded bg-white">
                            <div className="row mb-4">
                                <div className="col-12 rounded bg-white">
                                    <h3 className="title mb-0">
                                        Sokoban 1 PC-8801 (port from MULTI-8)
                                    </h3>
                                    <p className="text-right">
                                        <sub>Published: 2022 May 22</sub>
                                    </p>
                                    <p>
                                        The August 1984 issue of “POPCOM” printed the Program List
                                        of Sokoban for MULTI-8 and the port for FM-7, Sharp-X1,
                                        PC-8801, PC-9801, Mitsubishi-S1, PASOPIA 7.
                                    </p>
                                    <p>
                                        The PC-8801 port is slightly different from the commercial
                                        tape version in the main menu. In the gameplay, it shows the
                                        number of the warehouse. It also includes sound effects for
                                        walking and for the following situations:
                                    </p>
                                    <p>
                                        <ol>
                                            <li>When each warehouse stage is solved.</li>
                                            <li>When the game is over.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <img
                                            className={`img-fluid`}
                                            src={img_sokoban_pc8801_popcom_1984_issue_august_title}
                                            alt="Sokoban 1 (PC-8801) (POPCOM 1984 issue 8 version) main menu"
                                        />
                                    </p>
                                    <p>
                                        <img
                                            className={`img-fluid`}
                                            src={img_sokoban_pc8801_popcom_1984_issue_august_001}
                                            alt="Sokoban 1 (PC-8801) (POPCOM 1984 issue 8 version) level 1"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
