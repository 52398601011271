import React from "react";

import img_sokoban_pc8801_title from "../../../assets/screenshots/sokoban_pc8801_title.png";
import img_sokoban_pc8801_001 from "../../../assets/screenshots/sokoban_pc8801_001.png";
import img_sokoban_pc8801_011 from "../../../assets/screenshots/sokoban_pc8801_011.png";

export default function ArticleSokobanPc8801() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded bg-white">
                            <h3 className="title mb-0">Sokoban PC-8801</h3>
                            <p className="text-right">
                                <sub>Published: 2022 May 22</sub>
                            </p>
                            <p>
                                The first commercial Sokoban game was created in 1982 for
                                PC-8801 and was distributed in a cassette tape format in 1982.
                            </p>
                            <p>
                                <img
                                    className={`img-fluid`}
                                    src={img_sokoban_pc8801_title}
                                    alt="Sokoban 1 (PC-8801) title"
                                />
                            </p>
                            <p>
                                <img
                                    className={`img-fluid`}
                                    src={img_sokoban_pc8801_001}
                                    alt="Sokoban 1 (PC-8801) level 1"
                                />
                            </p>
                            <p>
                                The source code of the game was also included in the 1987 book
                                "THE 倉庫番" (pages 117-122).
                            </p>
                            <p>
                                This game has 20 levels, but only the first 10 levels used the
                                main Sokoban mechanics. Levels 11 to 20 adopted a secret
                                mechanic: the player can break some predefined walls by pressing
                                them from a certain side, leaving a free space in their wake.
                            </p>
                            <p>
                                The breakable walls did not differ visually from ordinary walls,
                                but breaking them is essential to solving the level.
                            </p>
                            <p>
                                <img
                                    className={`img-fluid`}
                                    src={img_sokoban_pc8801_011}
                                    alt="Sokoban 1 (PC-8801) level 11"
                                />
                            </p>
                            <p>
                                Curiosity: The cassette tape for this game is physically blank on side B, but reading the content reveals the entire game is present on both sides!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
